import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import HomeHero from '@/containers/Home/HomeHero';
import HomeDiscover from '@/containers/Home/HomeDiscover';
import HomeCta from '@/containers/Home/HomeCta';
import HomeReviews from '@/containers/Home/HomeReviews';
import HomePartners from '@/containers/Home/HomePartners';
import HomeContactBox from '@/containers/Home/HomeContactBox';
import HomeStats from '@/containers/Home/HomeStats';

import {
  HomePageQuery,
  PrismicCategoriesCategoriesGroupType,
  PrismicCompanyStatsStatsGroupType,
  PrismicHomePageHeroCategoriesGroupType,
  PrismicPartnersPartnersGroupType,
} from '../../../graphql-types';

interface Props {
  data: HomePageQuery;
}

const HomeContainerRoot = styled.section`
  /* padding-bottom: ${({ theme }) => theme.spacing(20)}; */
`;

const HomeContainer = ({ data }: Props): JSX.Element => {
  const {
    hero_categories,
    // discover_section_title,
    // discover_content,
    // cta_section_title,
    // reviews_section_title,
    // reviews_background,
    // reviews,
    // partners_section_title,
    // partners_mobile_section_title,
  } = data?.prismicHomePage?.data || {};

  const { primary } = data?.prismicHomePageBodyContactbox || {};

  // const { categories } = data?.prismicCategories?.data || {};

  // const { partners } = data?.prismicPartners?.data || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  // const { stats } = data?.prismicCompanyStats?.data || {};

  const HeroProps = {
    categories: hero_categories?.map(
      (el: PrismicHomePageHeroCategoriesGroupType | null) => {
        const { background, mobile_background, title, content, link } =
          el || {};

        return {
          background: {
            url: background?.url,
            alt: background?.alt,
            mobileUrl: mobile_background?.url,
            mobileAlt: mobile_background?.alt,
          },
          title: <RichText render={title?.raw} />,
          content: <RichText render={content?.raw} />,
          link,
        };
      },
    ),
  };

  // const DiscoverProps = {
  //   title: <RichText render={discover_section_title?.raw} />,
  //   content: <RichText render={discover_content?.raw} />,
  //   categories: categories?.map(
  //     (el: PrismicCategoriesCategoriesGroupType | null) => {
  //       const { image, title } = el || {};

  //       return {
  //         image: {
  //           url: image?.url,
  //           alt: image?.alt,
  //         },
  //         name: <RichText render={title?.raw} />,
  //       };
  //     },
  //   ),
  // };

  // const CtaProps = {
  //   title: <RichText render={cta_section_title?.raw} />,
  // };

  // const ReviewsProps = {
  //   title: <RichText render={reviews_section_title?.raw} />,
  //   image: {
  //     url: reviews_background?.url,
  //     alt: reviews_background?.alt,
  //   },
  //   reviews: reviews?.map((el: any | null) => {
  //     const { description, author, rating } = el?.review?.document?.data || {};

  //     return {
  //       rating,
  //       description: <RichText render={description?.raw} />,
  //       author,
  //     };
  //   }),
  // };

  // const PartnersProps = {
  //   title: <RichText render={partners_section_title?.raw} />,
  //   mobileTitle: <RichText render={partners_mobile_section_title?.raw} />,
  //   partners: partners?.map((el: PrismicPartnersPartnersGroupType | null) => ({
  //     name: el?.name,
  //   })),
  // };

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  // const StatsProps = {
  //   stats: stats?.map((el: PrismicCompanyStatsStatsGroupType | null) => ({
  //     number: <RichText render={el?.number?.raw} />,
  //     label: el?.label,
  //   })),
  // };

  return (
    <HomeContainerRoot>
      <HomeHero {...HeroProps} />
      {/* <HomeDiscover {...DiscoverProps} />
      <HomeCta {...CtaProps} />
      <HomeReviews {...ReviewsProps} />
      <HomePartners {...PartnersProps} /> */}
      {primary && <HomeContactBox {...ContactBoxProps} />}
      {/* <HomeStats {...StatsProps} /> */}
    </HomeContainerRoot>
  );
};

export default HomeContainer;
