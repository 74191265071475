import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import HomeContainer from '@/containers/Home/HomeContainer';
import Seo from '@/components/atoms/Seo';

import { HomePageQuery } from '../../graphql-types';

interface Props {
  data: HomePageQuery;
}

export const query = graphql`
  query HomePage {
    prismicHomePage {
      data {
        meta_title
        meta_description
        ...HomeHero
        ...HomeDiscover
        ...HomeCta
        ...HomeReviews
        ...HomePartners
      }
    }
    prismicHomePageBodyContactbox {
      primary {
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
    prismicCategories {
      data {
        ...Categories
      }
    }
    prismicPartners {
      data {
        ...Partners
      }
    }

    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
  }
`;

const Index = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } = data?.prismicHomePage?.data || {};

  return (
    <Layout show={false} type="magasin">
      <Seo
        title={meta_title || 'Home'}
        description={meta_description || 'Guilmin'}
      />
      <HomeContainer data={data} />
    </Layout>
  );
};

export default Index;
