import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Background from '@/components/atoms/Background';
import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';

import mq from '@/styles/mq';

import BackgroundDataType from '@/types/data/background';
import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';

interface CategoryProps {
  background?: BackgroundDataType;
  title?: React.ReactNode;
  content?: React.ReactNode;
  link?: React.ReactNode;
}

interface Props {
  categories?: CategoryProps[];
}

const HomeHeroRoot = styled(Grid)``;
const CategoryRoot = styled(Grid)``;
const TextContainer = styled('div')`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};

  ${mq('lg')} {
    min-height: 100vh;
    padding-top: ${({ theme }) => theme.spacing(30)};
    padding-bottom: ${({ theme }) => theme.spacing(30)};
  }
`;
const Title = styled(Typography)`
  text-align: center;
`;
const Content = styled(Typography)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
const CategoryButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${mq('lg')} {
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }
`;

const Category = ({
  background,
  title,
  content,
  link,
}: CategoryProps): JSX.Element => {
  return (
    <CategoryRoot xxs={12} lg={6}>
      <Background overlay={0.4} background={background}>
        <TextContainer>
          <Title variant="h1" color="white">
            {title}
          </Title>
          <Content variant="textMd" color="white">
            {content}
          </Content>
          <CategoryButton size="sm" as={Link} {...{ to: `${link}` }}>
            En savoir plus
          </CategoryButton>
        </TextContainer>
      </Background>
    </CategoryRoot>
  );
};

const HomeHero = ({ categories }: Props): JSX.Element => {
  const Categories = categories?.map((category, index) => (
    <Category key={index.toString()} {...category} />
  ));

  return <HomeHeroRoot container>{Categories}</HomeHeroRoot>;
};

export const query = graphql`
  fragment HomeHero on PrismicHomePageDataType {
    hero_categories {
      link
      background {
        url
        alt
      }
      mobile_background {
        url
        alt
      }
      title {
        raw
      }
      content {
        raw
      }
    }
  }
`;

export default HomeHero;
